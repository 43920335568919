@import 'normalize.css';
@import '~antd/dist/antd.css';

@font-face {
    font-family: 'Airfool';
    src: url('../fonts/Airfool.eot');
    src: local('☺'), url('../fonts/Airfool.woff') format('woff'),
        url('../fonts/Airfool.ttf') format('truetype'), url('../fonts/Airfool.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}

.container-main > main {
    width: calc(100% - 350px) !important;
    @media screen and (max-width: 1024px) {
        width: 100% !important;
    }
}

body {
    background: transparent !important;
}
#webbot {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #000;
    font-weight: 400;
    code {
        font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
    }
    p {
        margin: 0;
    }
    svg {
        vertical-align: unset;
    }
}
#webbot {
    code {
        font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
    }
    p {
        margin: 0;
        padding: 0;
    }
    .button {
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
        'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
        background: transparent;
        margin: 0;
        padding: 0;
        border: 0;
        outline: none;
        transition: 0.3s;
        cursor: pointer;
        position: relative;
        z-index: 1;
        text-align: center;
        display: table;
        font-weight: 500;

        &_l {
            padding: 12px 20px;
        }

        &_red {
            background: #e52127;
            border-radius: 30px;
            color: #fff;
        }
    }
    .game {
        background-image: url('../img/game/inner.svg');
        background-position: center center;
        background-repeat: no-repeat;
        margin: 0 auto;
        border-radius: 15px;
        max-width: 1076px;
        height: 484px;
        position: relative;
        overflow: hidden;

        &__logo {
            font-family: 'Airfool', sans-serif;
            text-align: center;
            color: #70d199;
            font-size: 64px;
            line-height: 1;
            text-shadow: 10px 0 10px #fff, 0 10px 10px #fff, -10px 0 10px #fff, 0 -10px 10px #fff;
        }

        &-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;
            padding-left: 15px;

            &__title {
                font-size: 26px;
            }
        }

        &-rule {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 106px;
            height: 106px;
            border-radius: 8px;
            font-weight: bold;
            font-size: 48px;
            margin: 0 auto;
            color: #fff;
        }

        &-body {
            border-top: 1px solid #e5e5e5;
        }

        &-footer {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 10px;
            border-top: 1px solid #e5e5e5;
        }

        &-board {
            &__tiles {
                display: flex;
                justify-content: flex-start;
                align-items: stretch;
                flex-wrap: wrap;
                padding: 1px;
            }

            &__tile {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-basis: 70px;
                box-sizing: border-box;
                height: 70px;
                font-size: 32px;
                margin: 9px;
                cursor: pointer;
                -ms-user-select: none;
                -moz-user-select: none;
                -khtml-user-select: none;
                -webkit-user-select: none;
                transition: 0.3s;
                color: #fff;
                border-radius: 8px;
                font-weight: bold;

                &[aria-disabled='true'] {
                    opacity: 0.1;
                    pointer-events: none;
                }
            }
        }

        &-score {
            margin-top: 30px;

            &__icon {
                background-image: url('../img/game/score.svg');
                background-position: center center;
                background-repeat: no-repeat;
                background-size: contain;
                width: 25px;
                height: 25px;
                display: inline-block;
                vertical-align: middle;
            }

            &__title {
                display: inline-block;
                vertical-align: middle;
                font-weight: bold;
                font-size: 36px;
                line-height: 1;
                color: #4f4f4f;
                margin-left: 10px;
            }
        }

        &-timer {
            margin-top: 45px;

            &__icon {
                background-image: url('../img/game/time.svg');
                background-position: center center;
                background-repeat: no-repeat;
                background-size: contain;
                width: 25px;
                height: 25px;
                display: inline-block;
                vertical-align: middle;
            }

            &__title {
                display: inline-block;
                vertical-align: middle;
                font-weight: bold;
                font-size: 36px;
                line-height: 1;
                color: #4f4f4f;
                margin-left: 10px;
            }
        }

        &-gameplay {
            background-color: rgba(196, 196, 196, 0.2);
            height: 100%;
            position: relative;

            &__container {
                display: flex;
                justify-content: center;
                align-items: stretch;
                flex-wrap: wrap;
                padding-top: 15px;
                box-sizing: border-box;
            }

            &__col {
                background: #ffffff;
                border-radius: 8px;

                &_left {
                    max-width: 452px;
                    padding: 5px;
                    box-sizing: border-box;
                }

                &_right {
                    width: 135px;
                    margin-left: 10px;
                    padding: 15px;
                    box-sizing: border-box;
                }
            }
        }

        &-start {
            &__overlay {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 100%;
                padding-top: 30px;
                box-sizing: border-box;
            }

            &__inner {
                width: 375px;
                background: rgba(255, 255, 255, 0.8);
                border-radius: 16px;
                margin: 0 auto;
                padding: 35px;
                box-sizing: border-box;
                margin-top: 20px;
            }

            &__title {
                text-align: center;
                font-weight: bold;
                font-size: 18px;
                line-height: 24px;
                color: #4f4f4f;
            }

            &__description {
                font-size: 16px;
                line-height: 20px;
                text-align: center;
                color: #4f4f4f;
                margin-top: 15px;
            }

            &__button {
                margin: 0 auto;
                margin-top: 25px;
                width: 270px;
                box-sizing: border-box;
            }
        }

        &-end {
            background-color: rgba(196, 196, 196, 0.2);
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            position: relative;

            &__overlay {
                height: 100%;
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                z-index: 2;
            }

            &__inner {
                width: 360px;
                padding: 30px;
                box-sizing: border-box;
                background: #ffffff;
                border-radius: 8px;
            }

            &__title {
                text-align: center;
                font-weight: bold;
                font-size: 24px;
                line-height: 16px;
                color: #4f4f4f;
            }

            &__score {
                margin: 0 auto;
                text-align: center;
                margin-top: 25px;
            }
        }
    }
    .ant-result-title {
        @media screen and (max-width: 479px) {
            font-size: 18px;
            line-height: 1.4;
        }
    }
    .result-warning {
        padding: 40px;
        & .ant-result {
            padding-bottom: 0;
        }
    }
    .retry-countdown {
        margin: 0 auto;
        margin-top: 20px;
        display: table;
        text-align: center;
    }

    .app_dark {
        .game {
            background-image: url('../img/game/inner_dark.svg');
            &__logo {
                text-shadow: none;
            }
            &-start {
                &__inner {
                    color: #fff;
                    background: rgb(16, 17, 18);
                }
                &__title {
                    color: #fff;
                }
                &__description {
                    color: #fff;
                }
            }
            &-gameplay {
                background-color: transparent;
            }
            &-end {
                background: transparent;
                &__inner {
                    background: rgb(16, 17, 18);
                    color: #fff;
                }
                &__title {
                    color: #fff;
                }
            }
            &-gameplay {
                &__col {
                    background: rgb(16, 17, 18);
                }
            }
            &-timer {
                &__title {
                    color: #fff;
                }
            }
            &-score {
                &__title {
                    color: #fff;
                }
            }
        }
    }
}
[data-dark-mode="true"] .ant-result-title {
    color: #fff;
}

